import React from 'react';
import Layout from '../components/layout';
import InsertMap from '../components/map';
import Seo from '../components/seo';

const ContactPage = () => {
    return (
        <Layout>
            <Seo title="Kontakt"/>
            <section className='contact-container'>
                <section className='contact-title'>
                    <h2 className='contact-title_question'>GDZIE JESTEŚMY</h2>
                    <h1 className='contact-title_name'>KONTAKT</h1>
                </section>
                <section className='contact-information'>
                    <div className='contact-information_map-container'>
                        <div className='contact-information_map-map'>
                            <InsertMap />
                        </div>
                    </div>
                    <div className='contact-information_adress'>
                        <div className='contact-information_adress-top_block'>
                            <h3 className='contact-information_adress-title'>Jeżeli masz pytania, napisz do nas</h3>
                        </div>
                        <div className='contact-information_adress-details'>
                            <a href="mailto:pilates.terapeutyczny@gmail.com">pilates.terapeutyczny@gmail.com</a>
                            <a href="https://www.facebook.com/pilatesterapeutyczny/" target="_blank">FACEBOOK</a>
                            <a href="https://www.instagram.com/pilatesterapeutyczny/" target="_blank">INSTAGRAM</a>
                        </div>
                        <div className='contact-information_adress-details'>
                            <p>ul. Wilcza 71 lok. 4, Warszawa<br/>drugie piętro, budynek z windą</p>
                            <p>Tel: +48 502 274 535 </p>
                        </div>
                    </div>
                </section>
            </section>
            <section className='faq margin-top altcolor'>
                <h2 className="english_title">FAQ</h2>
                <div>
                    <h3 className="english_question">Co mogą mi dać zajęcia Pilates? Po jakim czasie widać efekty?</h3>
                    <p className="english_subtext">Pilates to metoda pracy z ciałem, w której naprawiamy wzorce w układzie ruchu. Pracujemy nad świadomością i poprawną postawą ciała, kontrolą ruchu oraz przywróceniem równowagi i siły poszczególnych grup mięśniowych. Dzięki temu zapobiegamy kontuzjom oraz wyprowadzamy z ewentualnych dysfunkcji mięśniowych i dolegliwości bólowych. 
                    <br /><br />Nie jest to zwykły zbiór ćwiczeń, a metoda - czyli pracujemy w dużej precyzji i skupieniu na odpowiedniej aktywacji układu nerwowego, oddechowego i ruchowego.
                    <br /><br />Przeciętnie potrzeba ok 6 sesji, aby organizm zaczął realnie zmieniać wzorce w układzie ruchu, więc potrzebujemy tylko kilku tygodni, aby zobaczyć pierwsze widoczne efekty oraz odczuć pożądane wrażenia w ciele.
                    </p>
                </div>
                <div>
                    <h3 className="english_question">Czy Pilates jest bezpieczny dla każdego? Czy każdy może ćwiczyć Pilates?</h3>
                    <p className="english_subtext">Metoda Pilates jest najbezpieczniejszą formą pracy z ciałem, jaką do tej pory opracowano. Oparta jest na wiedzy o anatomii i neurologii. 
                    <br /><br />W trybie zajęć indywidualnych, kiedy instruktor całą swoją uwagę poświęca Twojemu przypadkowi, praktycznie każda osoba może w najbardziej efektywny sposób rozpracować to, co w ciele jest do naprawienia.
                    <br /><br />Pracujemy z różnymi osobami z tzw. grup wrażliwych, m.in. osobami z wyraźnymi wadami postawy, osobami po wypadkach, urazach czy zabiegach, kobietami w ciąży lub tuż po porodzie, kobietami przygotowującymi się do zajścia w ciążę, osobami starszymi, onkologicznymi czy niewidomymi, osobami z dysfunkcjami w aparacie ruchu wynikającymi z wad wrodzonych, chorób genetycznych czy autoimmunologicznych lub schorzeń nabytych. Ale również ze sportowcami czy tancerzami zawodowymi, którzy potrzebują na co dzień minimalizować potencjalne przeciążenia i zadbać o funkcjonalność i równowagę mięśniową w swoim ciele.
                    <br /><br />Z Pilatesu korzystają też często osoby zdrowe,  jako z po prostu ciekawej, angażującej aktywności fizycznej.
                    </p>
                </div>
                <div>
                    <h3 className="english_question">Czy Pilates jest trudny i czy można się zmęczyć lub spocić na Pilatesie?</h3>
                    <p className="english_subtext">Na początku skupiamy się na podstawach i  prostych wzorcach ruchu, budując umiejętność angażowania różnych układów czy układając rytm stawowy. Z czasem ćwiczenia stają się wielowymiarowe, angażujemy różne części ciała jednocześnie, zwiększamy tempo pracy oraz obciążenia i opory, możliwie do poziomu pracy prawie akrobatycznej, jeśli osoby chcą dążyć do poziomu perfekcji panowania nad kontrolą swojego ciała.
                    <br /><br />W Pilatesie wszystkie ćwiczenia wykonujemy w korelacji z oddechem i dbamy o odpowiednie dotlenienie, dlatego mimo względnego wysiłku, nie powinieneś odczuwać przesilenia. Natomiast ponieważ musimy całkowicie przeorganizować dotychczasowe przyzwyczajenia organizmu oraz ze względu na charakter tej metody, m.in. aktywację mięśni „rozleniwionych” i wyciszenie mięśni nadaktywnych, ćwiczenia mogą wydawać się trudne i możesz się nimi zmęczyć.
                    </p>
                </div>
                <div>
                    <h3 className="english_question">Czy Pilates to coś w rodzaju jogi?</h3>
                    <p className="english_subtext">Pilates to nie to samo co joga. Jest to bardziej współczesna metoda pracy z ciałem, oparta na nauce tzw. kultury zachodniej (czyli na współczesnych odkryciach z dziedziny anatomii m.in. biomechaniki czy fizjologii oddechu)
                    <br /><br />Natomiast, podobnie jak joga, Pilates należy do zajęć z kategorii „body and mind”, czyli w treningu angażujemy jednocześnie i praktycznie tak samo intensywnie ciało jak i umysł. Oznacza to, że świadomie pracujemy na zarządzaniu naszym ciałem poprzez precyzyjne polecenia umysłu, skupienie na oddechu i równowadze.</p>
                </div>
                <div>
                    <h3 className="english_question">Co to jest Reformer i jak się na nim ćwiczy?</h3>
                    <p className="english_subtext">Reformer jest najbardziej uniwersalną maszyną Pilatesową, pozwala na pracę w bardzo wielu pozycjach ciała, płaszczyznach i osiach ruchu, z wykorzystaniem własnego ciężaru ciała ale również ciągów i dodatkowych oporów, a także pracy na niestabilnym podłożu.
                    <br /><br />Same początki metody Pilates zostały opracowane podczas pierwszej wojny światowej jako element rehabilitacji żołnierzy i jeńców wojennych w szpitalach polowych. Już wtedy Joseph Pilates, twórca tej metody, konstruował maszyny z dostępnych na miejscu elementów (łóżek polowych, sprężyn, lin, drążków, krzeseł, beczek, itp.). Współczesne maszyny Pilatesowe, w tym Reformer, są bardzo podobne do tych oryginalnych, bo odzwierciedlają podobne funkcjonalności i założenia.</p>
                </div>
                <div>
                    <h3 className="english_question">Jak przygotować się do zajęć Pilates?</h3>
                    <p className="english_subtext">Na treningach ćwiczymy w wygodnym, niekrępującym ruchów stroju. Ćwiczymy bez butów (albo na boso albo w skarpetkach albo raz tak raz tak, w zależności od potrzeby większej stabilizacji lub większego ślizgu).
                    <br /><br />Warto też zadbać o to, by na wszelki wypadek mieć ze sobą matę do ćwiczeń, gdyby fragment zajęć był przeprowadzony na podłodze,  np.  po to, by oswoić się z jakimś ruchem zanim wykonamy go na maszynie. Ale dostępne są też u nas maty uniwersalne, z których możesz korzystać i które dezynfekujemy na bieżąco. Natomiast i tak dobrze jest posiadać swoją matę do Pilatesu, bo mamy nadzieję, że przyda Ci się w domu, abyś mógł niektóre bardzo korzystne ćwiczenia wykonywać częściej niż tylko na zajęciach w Studio, aby efekt Twojej pracy z ciałem był jak najlepszy.
                    <br /><br />Tymczasem, aby w ogóle zacząć u nas treningi indywidualne, pierwsze spotkanie jest konsultacją diagnostyczną i aby do niej się przygotować, musimy wymienić się kwestionariuszem diagnostycznym, w którym odpowiadasz na pytania związane z historią Twojego zdrowia, urazów, stylem życia oraz celami do pracy z ciałem. Możesz też dodatkowo przynieść wyniki badań obrazowych układu ruchu jeśli takie posiadasz (zdjęcia rentgenowskie lub same opisy, opisy badania rezonansu magnetycznego lub USG), ale nie jest to wymagane.
                    <br /><br />W samym kwestionariuszu, który dostaniesz od nas na początku naszej współpracy znajdziesz wszystkie wskazówki, jak dokładnie przygotować się do sesji diagnostycznej oraz do zajęć Pilates.
                    </p>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage;