import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { GoogleMap, useLoadScript, InfoWindow,  Marker } from "@react-google-maps/api";



const InsertMap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.API_KEY,
    });

    if (!isLoaded) return <div>Loading...</div>;

    return <Map />
}

const adres = [{
    id: "wilcza",
    name: "Pilates Terapeutyczny",
    position: {lat: 52.22339440929214, lng: 21.008759882862684}
}]

function Map() {
    const [activeMarker, setActiveMarker] = useState(true);
    const handleActiveMarker = () => {
        setActiveMarker(!activeMarker);
    }; 

    const center = useMemo(() => ({
        lat: 52.22339440929214, 
        lng: 21.008759882862684
    }), []);
    
    return ( 
        <GoogleMap 
            zoom={15} 
            center={center}
            onClick={() => setActiveMarker()} 
            mapContainerClassName="contact-information_map-map"
            options={{streetViewControl: false}}    
        >
            {adres.map(({ id, name, position }) => (
                <Marker position={position} key={id} onClick={()=>handleActiveMarker()}> 
                    {activeMarker === true ? ( 
                        <InfoWindow  onCloseClick={()=>setActiveMarker()} position={{lat: 52.22360541939315, lng: 21.008759882862684}}>
                            <div className="mapa-container">
                                <p>{name}</p>
                                <p>ul. Wilcza 71 lok. 4<br/>drugie piętro</p>
                                <a href="https://www.google.com/maps?ll=52.22324,21.0087&z=16&t=m&hl=en&gl=PL&mapclient=embed&cid=2580676489993665930" target="_blank">Otwórz na mapach google</a>
                            </div>
                        </InfoWindow> ) 
                    : null}
                </Marker> 
            ))}
        </GoogleMap>
    )
}

export default InsertMap;

